import { Controller } from "@hotwired/stimulus";
import { ProgressDonut } from "bootstrap-italia"

export default class extends Controller {

  connect() {
    let value = this.element.dataset.bsValue
    let options = {}
    switch(true) {
      case (value <= 0.2):
        options = { color: "#95A1AE" }
        break;
      case (value <= 0.4):
        options = { color: "#8795A3" }
        break;
      case (value <= 0.6):
        options = { color: "#798999" }
        break;
      case (value <= 0.8):
        options = { color: "#6B7C8E" }
        break;
      case (value < 1.0):
        options = { color: "#5D7083" }
        break;
      case (value == 1.0):
        options = { color: "#7EB330" }
        break;
      default:
        options = {}
    }
    new ProgressDonut(this.element, options)
  }

  disconnect() {
    ProgressDonut.getInstance(this.element).dispose()
  }

}