import { Controller } from "@hotwired/stimulus";

import dependsOn from 'jquery-depends-on';
import scrollTo from 'jquery.scrollto';
import alertOnChange from "../dependsOn/depends_on_alert";
import addAnchor from "../dependsOn/depends_on_add_icon";
import { getIsCheckboxOrRadio } from "../helpers/helpers";

export const DEPENDS_ON_NORESET = 'depends_on_noreset'

export default class DependsOn extends Controller {

  connect() {
    Array.prototype.unique = function () {
      let key;
      let asc, end;
      const output = {};
      for (key = 0, end = this.length, asc = 0 <= end; asc ? key < end : key > end; asc ? key++ : key--) { output[this[key]] = this[key]; }
      return (() => {
        const result = [];
        for (key in output) {
          const value = output[key];
          result.push(value);
        }
        return result;
      })();
    };

    const applyDependsOn = function ($element, selector, key, value) {
      const objectValue = {};
      objectValue[key] = value;

      const object = {
        disable: false
      };
      object[selector] = objectValue;

      const objectEvents = {};
      objectEvents.onEnable = () => $(window).trigger('dependOn');

      objectEvents.valueOnDisable = function () {
        // cambio valore solo se il campo non è radio o checkbox
        if (!getIsCheckboxOrRadio($(this))) {
          const _this = $(this);
          // Alcuni campi hidden non vanno mai svuotati
          const noReset =
            (_this.attr('type') === 'hidden' && _this.parent('.ep-auto-complete-module').length)
            || _this.parents(`.${DEPENDS_ON_NORESET}`).length

          if (noReset) {
            return _this.val()
          } else {
            setTimeout((function () { }), 300);
            // reset tomselect input
            if (_this.attr('class') && _this.attr('class').includes('tomselected')) {
              $element = document.getElementById(_this.attr('id'));
              $element.tomselect.clear();
            }
            // Reset flatpickr input
            if (_this.attr('class') && _this.attr('class').includes('flatpickr-input')) {
              $element = document.getElementById(_this.attr('id'));
              $element.setAttribute('value', '')
              $element._flatpickr.clear();
              $element._flatpickr.jumpToDate(new Date(), true);
              $element._flatpickr.redraw();
            }
            return null
          }
        } else {
          return $(this).val()
        }
      };

      objectEvents.checkOnDisable = () => false;
      return $element.dependsOn(object, objectEvents);
    };


      const $elements = $('.depends-on').not('.depends-on-init');

      // Ciclo tra tutti i div a cui devo applicare la regola
      let i = 0;
      return (() => {
        const result = [];
        while (i < $elements.length) {
          var $element = $($elements[i]);
          i++;

          // alertOnChange($element);

          addAnchor($element);
          $element.addClass('depends-on-init');

          // L'attributo data-name indica l'elemento da controllare
          const dataName = $element.data('name');
          // L'attributo data-value indica l'array di valori per cui il risultato sarà true.
          //Se ci sono più valori NON DEVONO ESSERCI SPAZI dopo la virgola
          var dataValue = $element.data('value').toString().split(",");

          // Escluto i campi hidden perchè In caso di radio o checkbox viene aggiunto un campo hidden con lo stesso name
          const field_to_check = `[name='${dataName}'][type!='hidden']`; // selettore stringa

          const type = ($(field_to_check).attr('type'));
          if (type === 'checkbox') {
            $(field_to_check).each(function () {
              let checkboxSelector;
              if ($.inArray($(this).attr('value'), dataValue) > -1) {
                checkboxSelector = '#' + $(this).attr('id');
                return applyDependsOn($element, checkboxSelector, 'checked', true);
              } else {
                checkboxSelector = '#' + $(this).attr('id');
                return applyDependsOn($element, checkboxSelector, 'checked', false);
              }
            });
            continue;
          }

          let qualifiers = 'values';
          // se è una select multipla
          if ($(field_to_check).attr('multiple')) {
            // non prevede valori 'and'
            // per farlo bisogna che dataValue sia un array di array: dataValue = [dataValue]
            qualifiers = 'contains';
          }
          if ($element.data('options') === 'not') {
            qualifiers = 'not';
          }
          result.push(applyDependsOn($element, field_to_check, qualifiers, dataValue));
        }

        // document.formValues = $('form:not(.devise-form, .search-form)').serialize();
        return result;
      })();

    ;





  }
}