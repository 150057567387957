/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import noUiSlider from 'nouislider';
// import 'nouislider/distribute/nouislider.css';

const Slider = function (el) {
  console.log('sliders')
  this.input = el;
  this.setOptions();
  this.makeSlider();
  return this.setInputInit();
};


Slider.prototype.setOptions = function() {
  this.initvalue = this.input.val();
  if (this.initvalue === '') { this.initvalue = ''; }
  this.sliderOptions = {};
  const optionMin = this.input.data('slider-min') || 0;
  const optionMax = this.input.data('slider-max') || 10;

  return this.sliderOptions = {
    connect: [true, false],
    direction: this.input.data('slider-direction') || 'ltr',
    pips: {
      mode: 'values',
      density: 10,
      values: [optionMin, optionMax]
    },
    range: {
      min: optionMin,
      max: optionMax
    },
    step: this.input.data('slider-step') || 1,
    start: this.input.data('slider-value') || 0
  };
};


Slider.prototype.makeSlider = function() {
  this.input.wrap('<div class="slider-wrap"></div>');
  const sliderId = this.input.attr('id') + '_slider';
  this.slider = $('<div>', {id: sliderId});
  this.input.after(this.slider);

  noUiSlider.create(this.slider[0],
    this.sliderOptions);

  const _this = this;
  return this.slider[0].noUiSlider.on('update', function(values, handle) {
    const value = values[handle];
    return _this.input.val(Math.floor(value));
  });
};
    

Slider.prototype.setInputInit = function() {
  return this.input
    .val(this.initvalue);
};


window.startSliders = () => $('input.slider').each(function() {
  return new Slider($(this));
});
