import { Controller } from "@hotwired/stimulus";
import { HeaderSticky } from "bootstrap-italia"

export default class extends Controller {

  connect() {
    
    new HeaderSticky(this.element);
    const scrolledLogo = document.querySelector('#scrolled-logo')
    // Show/hide logos on scroll
    const nav = document.querySelector('.it-header-slim-wrapper')
    window.addEventListener('scroll', () => {
      // Check if navbar is at top
      const navTop = nav.offsetTop
      const atTop = window.scrollY === 0
      if (scrolledLogo) {
        if (!atTop) {
          // Scrolled -> Visible
          scrolledLogo.classList.add('d-lg-block')
        } else {
          // Not scrolled -> Not visible
          scrolledLogo.classList.remove('d-lg-block')
        }
      }

    })
  }

}