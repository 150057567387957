import { Controller } from "@hotwired/stimulus";
import Swal from 'sweetalert2';
import { get, post, put, patch, destroy } from '@rails/request.js';

export default class UnsavedForm extends Controller {
  connect() {
    let form = $('form:not(.devise-form, .search-form)');
    this.formChanged = false;
    
    if (form.length > 0) {
      form[0].addEventListener('input', () => {
        this.formChanged = true;
      });
    }

    const links = this.getLinks();
    
    for (const link of links) {
      link.addEventListener('click', this.handleLinkClick.bind(this));
    }
  }

  getLinks() {
    return Array.from(document.getElementsByTagName('a')).filter(link => 
      link.getAttribute('href') !== '#' &&
      !link.dataset.skipUnsavedForm &&
      !link.classList.contains('add_nested_fields') &&
      !link.classList.contains('remove_nested_fields') &&
      !link.classList.contains('cf-block__btn')
    );
  }

  async handleLinkClick(event) {
    // event.preventDefault();
    const link = event.currentTarget;
    const newUrl = link.getAttribute('href');

    if (this.formChanged) {
      event.preventDefault();
      const result = await Swal.fire({
        text: i18n.t('unsaved'),
        showCancelButton: true,
        confirmButtonText: i18n.t('yes'),
        cancelButtonText: i18n.t('cancel')
      });

      if (result.value) {
        this.navigateWithTurbo(newUrl, link);
      }
    }
  }

  async navigateWithTurbo(url, link) {
    let turboFrame = link.closest('turbo-frame');
    const turboMethod = link.dataset.turboMethod || 'get';
    let turboFrameTarget = link.dataset.turboFrame || '';
    const turboAction = link.dataset.turboAction || 'advance';
    if (turboFrameTarget == '' && turboFrame && turboFrame.hasAttribute('target')) {
      turboFrameTarget = turboFrame.getAttribute('target');
    }
    Turbo.visit(url, { action: turboAction, method: turboMethod, frame: turboFrameTarget });
    
  }}