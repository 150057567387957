import Swal from 'sweetalert2';
import { getFormElements, getIsCheckboxOrRadio } from '../helpers/helpers';

import { DEPENDS_ON_NORESET } from '../controllers/depends_on';

// abilita il controllo con checkbox su tutti i campi modifciati
const useCheckbox = false
const button_suffix = '__button'
const alertListClass = 'depends-on-alert-list'

const getArrFieldsAttributes = function($fields) {
  const arrFieldsAttributes = [];
  $fields.forEach(function (item) {
    const $this = $(item)
    const hasNoResetParent = $this.parents(`.${DEPENDS_ON_NORESET}`).length
    if (hasNoResetParent) {
      return false
    }
    const isCheckboxOrRadio = getIsCheckboxOrRadio($this)
    if (
      (isCheckboxOrRadio && $this.prop('checked'))
      ||
      (!isCheckboxOrRadio && $this.val())
    ) {
      let value
      switch (true) {
        case ($this[0].type == 'select-one'):
          value = $this[0].options[$this[0].selectedIndex].text;
          break;
        case ($this.attr('type') === 'radio'):
          value = $this.siblings('label').text();
          break;
        case (isCheckboxOrRadio):
          value = 'true';
          break;
        default:
          value = $this.val();
      }
      const fieldAttributes = {
        name: $this.attr('name'),
        value,
        id: $this.attr('id')
      }
      arrFieldsAttributes.push(fieldAttributes);
    }
  });
  return arrFieldsAttributes;
};

const getI18nKeyParts = function(name) {
  let match_name = ''
  let questionnaire_regex
  let questionnaire_string_replaced
  // check if name is defined
  if (name != undefined) {
    questionnaire_regex = new RegExp('^' + QUESTIONNAIRE_PARAM_NAME + '_')
    questionnaire_string_replaced = QUESTIONNAIRE_PARAM_NAME + '/'
    match_name = name.replace("_attributes", "").replace(questionnaire_regex, questionnaire_string_replaced).match(/\w+/g).filter(x => isNaN(x))
  }
  let model_name
  let attr_name
  if (match_name.length == 2) {
    // Simple model: covid19/therapy.attr_name
    return {
      model_name: match_name[0],
      attr_name: match_name[1]
    }
  } else {
    // Nested model: covid19/therapy/therapy_drugs.attr_name
    return {
      model_name: [match_name[0], match_name[1]].join('/'),
      attr_name: match_name[2]
    }
  }
};

const getHtmlForAlert = function($changing, changingValue, arrStringInputs, isUnchecked, isResetRadio) {
  const html = $('<div/>')

  const elements = $('<div/>', { class: alertListClass })
  
  arrStringInputs.forEach(function (item) {
    //skip if item.name is undefined
    const key_parts = getI18nKeyParts(item.name)
    const key = `activerecord.attributes.${key_parts.model_name}.${key_parts.attr_name}`;
    const default_message_for_missing_translation = i18n.t(`simple_form.labels.defaults.${key_parts.attr_name}`);
    const text = i18n.t("alert_on_change_inputs_html", { locale: `${i18n.locale}`, input_label: `${i18n.translate(key, { defaultValue: default_message_for_missing_translation })}`,  input_value: `${item.value}`})

    const element = $('<div>', { class: useCheckbox ? 'checkbox' : 'my-15' })
    let checkbox
    let label = useCheckbox ? $('<label/>', { html: text, for: `${item.id}${button_suffix}`}) :  $('<div/>', { html: text })
    if (useCheckbox) {
      checkbox = $('<input/>', {type: 'checkbox', id: `${item.id}${button_suffix}`})
      element.append(checkbox)
    }
    element.append(label)
    elements.append(element)
  });

  const changing_key_parts = getI18nKeyParts($changing.attr('name'))
  const changing_key =  `activerecord.attributes.${changing_key_parts.model_name}.${changing_key_parts.attr_name}`;

  let i18n_alert_key
  switch (true) {
    case (isUnchecked):
      i18n_alert_key = "alert_on_change_unchecked_html";
      break;
    case (isResetRadio):
      i18n_alert_key = "alert_on_change_reset_radio_html";
      break;
    default:
      i18n_alert_key = "alert_on_change_html";
  }

  const text = i18n.t(
    i18n_alert_key,
    {
      locale: `${i18n.locale}`, 
      input_label: `${i18n.translate(changing_key)}`, 
      input_value: `${changingValue}`,
      number: arrStringInputs.length
    }
  );
  const textEnd = i18n.t("alert_on_change_end_html")
  html.append(text).append(elements).append(textEnd)
  return html;
};

const getIsAllChecked = ($el) => {
  const wrapper = $el.closest('.' + alertListClass)
  const checkboxes = wrapper.find('[type=checkbox');
  let allChecked = true
  checkboxes.each(function(){
    if (!$(this).prop('checked')) {
      allChecked = false
    }
  })
  return allChecked
}

const onSwalOpen = (arrFieldsAttributes) => {
  if (useCheckbox) {
    $(Swal.getConfirmButton()).prop('disabled', true)
    arrFieldsAttributes.forEach((item) => {
      $(`#${item.id}${button_suffix}`).on('change', function() {
        const isAllChecked = getIsAllChecked($(this))
        $(Swal.getConfirmButton()).prop('disabled', !isAllChecked)
      })
    })
  }
}


async function wait(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

const showAlert = async function(params) {
  const { $element, $changing, changingLabel, isUnchecked, isResetRadio } = params
  const $fields = getFormElements($element);
  // arrFieldsAttributes = array di campi {name, value, id}
  const arrFieldsAttributes = getArrFieldsAttributes($fields);
  const swalHtml = getHtmlForAlert($changing, changingLabel, arrFieldsAttributes, isUnchecked, isResetRadio);

  if (arrFieldsAttributes.length > 0) {
    Swal.fire({
      html: swalHtml || '',
      showCancelButton: true,
      confirmButtonText: i18n.translate('yes'),
      cancelButtonText: i18n.translate('cancelCTA'),
      width: '90%',
      // grow: 'column'
      // onOpen: function (){ onSwalOpen(arrFieldsAttributes) },
    }).then((result) => {
      if (result.isConfirmed) {
        $(document).trigger('depends_on_alert_confirmed', $fields)
        var evt = new CustomEvent("depends_on_alert_confirmed", { detail: $fields });
        window.dispatchEvent(evt);
        return Boolean(result.isConfirmed)
      }
      
      else {
        // se non ha elementi che controlla ritorna true e permette il change
        
        return true
      }
    });
  };

    
    
};

function setIsValidValue(params) {
  const { dataValues, changingValue, isInclude, isCheckbox, isChecked } = params

  // se è checkbox devo controllare solo che il valore sia spuntato o meno
  let  isInArrShowValue = isCheckbox ? !isChecked : dataValues.includes(changingValue);

  let isUnchecked = false
  let isResetRadio = false
  if (!isCheckbox && isChecked) {
    // sto facendo reset su un campo radio
    isInArrShowValue = !isInArrShowValue
    isResetRadio = true
  } else if (isCheckbox) {
    // sto deselezionando un campo checkbox
    isUnchecked = true
  }

  if (!isInclude) {
    isInArrShowValue = !isInArrShowValue
  }
  return { isInArrShowValue, isUnchecked, isResetRadio }
}

const setAttributeOnClicked = (e) => {
    // l'evento è impostato sulla label.
    // per avere i riferimento del radio o del checkbox cerco il sibling che non sia di type hidden
    const $changing = $(e.target).siblings('input:not([type=hidden])');
    const changingValue = $changing.val()
    const isCheckbox = $changing.attr('type') === 'checkbox'
    // prende il valore del click già applicato, L'evento click precede l'evento change
    const isChecked = $changing.prop('checked')
    return { $changing, changingValue, isCheckbox, isChecked }
}

const onElementClicked = async (e, params) => {
  const { $element, $elementsRef, dataValues, isInclude } = params
  window.enableRadioReset = false

  const { $changing, changingValue, isCheckbox, isChecked } = setAttributeOnClicked(e)
  const {isInArrShowValue, isUnchecked, isResetRadio} = setIsValidValue({
    dataValues, isInclude, changingValue, isCheckbox, isChecked
  })
  
  const hasNoresetParent = $element.parents('.depends_on_noreset').length
  if (!isInArrShowValue) {
    e.preventDefault()
    const changingLabel = $changing.siblings('label').text();
    const swalResult =  showAlert({ $element, $changing, changingLabel, isUnchecked, isResetRadio })
    if (swalResult) {
      let newValue = isCheckbox ? !isChecked : true
      // sto facendo reset su un campo radio
      if (!isCheckbox && isChecked) {
        newValue = false
        // vedi resetRadio
        $changing.trigger('resetRadioDelete')
      }
      $changing.prop('checked', newValue).trigger('change')
    }
  }
  // con timeout per evitare che si attivi resetRadio
  setTimeout(() => {
    window.enableRadioReset = true
  }, 200)
}

const eventOnRadioAndCheckbox = (params) => {
  const { $elementsRef, $element } = params
  $elementsRef.siblings('label').on('click', function(e) {
    // Più elementi potrebbero essere controllati dallo stesso campo
    // attivo il controllo solo su quello visibile per non far passare il ciclo n volte
    const isHidden = $element.is(':hidden')
    if (!isHidden) {
      onElementClicked(e, params)
    }
  });
}

const eventOnSelect = (params) => {
  const { $element, $elementsRef, dataValues, isInclude } = params
  $elementsRef.on('select2:selecting', async function(e) {
    const changingValue = e.params.args.data.id;
    const {isInArrShowValue, isUnchecked, isResetRadio} = setIsValidValue({ dataValues, changingValue, isInclude })

    if (!isInArrShowValue) {
      e.preventDefault()
      const $changing = $(this);
      const changingLabel = e.params.args.data.text;
      const swalResult = await showAlert({ $element, $changing, changingLabel, isUnchecked, isResetRadio });
      if (swalResult) {
        $(this).val(changingValue)
        $(this).trigger('change');
      }
    }
  })
}

const alertOnChange = function($element) {
  const dataValues = $element.data('value').toString().split(',');
  // mi dice se dependsOn è impostato per accettare l'array di campi in inclusione o esclusione
  const isInclude = Boolean($element.data('options') !== 'not')
  const name = $element.data('name').replace(/\[/g, '\\[').replace(/\]/g, '\\]');
  const $elementsRef = $('[name=' + name + ']');

  // attivo il controllo solo se i campi che controllano dependsOn sono radio, checkbox o select
  const isCheckboxOrRadio = getIsCheckboxOrRadio($elementsRef);
  const isSelect = $elementsRef.is('select');

  if (isSelect) {
    eventOnSelect({ $element, $elementsRef, dataValues, isInclude })
  }

  if (isCheckboxOrRadio) {
    eventOnRadioAndCheckbox({ $element, $elementsRef, dataValues, isInclude })
  }
};

export default alertOnChange;
