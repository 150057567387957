import './jquery'
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"
import { I18n } from "i18n-js";
import translations from "./locales.json";
import "./sliders"
startSliders();


const i18n = new I18n(translations);
i18n.locale = ENVIRONMENT.lang;
window.i18n = i18n;