/* 
	Struttura html necessaria:
	tag contenitore del form deve avere classe "cf-block" e attributo "data-url"
	input che si compilerà con i dati ricevuti dalla chiamata ajax deve avere classe "cf-block__input"
	il bottone che all'evento click fa partire la chiamata ajax deve avere classe "cf-block__btn"
*/
import Swal from 'sweetalert2';


const FCblock = function(block) {
	this.defineRequired(block);
	this.elements(block);
	this.events();
	return this;
};

FCblock.prototype.defineRequired = function(block) {
	this.block = block;
	var first_name = this.block.data('first-name') || 'first_name';
	var last_name = this.block.data('last-name') || 'last_name';
	var sex = this.block.data('sex') || 'sex';
	var date_of_birth = this.block.data('date-of-birth') || 'date_of_birth';
	var birth_city = this.block.data('birth-city') || 'birth_city';
	var birth_province_region_state = this.block.data('birth-province-region-state') || 'birth_province_region_state';
	var birth_country = this.block.data('birth-country') || 'birth_country';

	this.required = [
		first_name,
		last_name,
		sex,
		date_of_birth,
		birth_city,
		birth_province_region_state,
		birth_country
	];
};

FCblock.prototype.elements = function(block) {
	this.block = block;
	this.inputCf = this.block.find('.cf-block__input input');
	this.btn = this.block.find('.cf-block__btn');

	this.originalText = this.btn.text();

	this.object = this.block.data('object');
	this.first_name = this.block.data('first-name');
	this.last_name = this.block.data('last-name');
	this.sex = this.block.data('sex');
	this.date_of_birth = this.block.data('date-of-birth');
	this.birth_city = this.block.data('birth-city');
	this.birth_province_region_state = this.block.data('birth-province-region-state');
	this.birth_country = this.block.data('birth-country');

	this.url = this.block.data('url');
};
FCblock.prototype.events = function() {
	var self = this;
	this.btn.on('click', function(event) {
		return self.sendData(event);
	});
};

FCblock.prototype.checkIsValid = function() {
	let object = this.object;
	let first_name = this.first_name || 'first_name';
	let last_name = this.last_name || 'last_name';
	let sex = this.sex || 'sex';
	let date_of_birth = this.date_of_birth || 'date_of_birth';
	let birth_city = this.birth_city || 'birth_city';
	let birth_province_region_state = this.birth_province_region_state || 'birth_province_region_state';
	let birth_country = this.birth_country || 'birth_country';

	let isValid = true;
	let $fields;
	$.each(this.required, function(index, item) {
		$fields = $('[name="' + object + '[' + item + ']"]');
		if (item === sex) {
			isValid = false;
			$fields.each(function() {
				if ($(this).is(':checked')) { isValid = true; }
			});
		} else {
			if (!$fields.val()) { isValid = false; }
		}
		// se isValid è false anche per un solo campo esco dal ciclo
		return isValid;
	});

	return isValid;
};

FCblock.prototype.sendData = function(event) {
	const self = this;
	const url =  this.url;
	const object_name = this.object;
	const first_name = this.first_name || 'first_name';
	const last_name = this.last_name || 'last_name';
	const sex = this.sex || 'sex';
	const date_of_birth = this.date_of_birth || 'date_of_birth';
	const birth_city = this.birth_city || 'birth_city';
	const birth_province_region_state = this.birth_province_region_state || 'birth_province_region_state';
	const birth_country = this.birth_country || 'birth_country';

	if (!this.checkIsValid()) {
		Swal.fire({
			title: "",
			text: i18n.t('it_fiscal_code_requirements'),
			type: 'warning',
		})
		return false;
	}

	$.ajax({
		url : url +
			"?object_name=" + object_name +
			"&first_name=" + first_name +
			"&last_name=" + last_name +
			"&sex=" + sex +
			"&date_of_birth=" + date_of_birth +
			"&birth_city=" + birth_city +
			"&birth_province_region_state=" + birth_province_region_state +
			"&birth_country=" + birth_country,
		data :  $('form').serialize(),
		dataType : 'html',
		success : function (data) {
			self.inputCf.val(data);
			self.btn.text(self.originalText);
		},
		error : function (error) {}
	});
	return event.preventDefault();
};

export default FCblock;