import { Controller } from "@hotwired/stimulus";
import intlTelInput from "intl-tel-input";
import { it } from "intl-tel-input/i18n"
export default class extends Controller {

  static targets = ["phone"]

  connect() {
    const input = this.phoneTarget;
    intlTelInput(input, {
      i18n: {
        ...it,
      },
      initialCountry: "it",
      loadUtils: () => import("intl-tel-input/utils"),
      hiddenInput: (telInputName) => ({
        phone: input.name,
      }),
      autoPlaceholder: 'off'
    });
  }
}
